import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { signup } from "../../services/index/users";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/reducers/userReducer";
import HCaptcha from "@hcaptcha/react-hcaptcha";

export default function RegisterPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const [hCaptchaToken, setHCaptchaToken] = useState("");
  const [hCaptchaEkey, sethCaptchaEkey] = useState("");

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ name, email, password, hCaptchaToken, hCaptchaEkey }) => {
      return signup({ name, email, password, hCaptchaToken, hCaptchaEkey });
    },
    onSuccess: (data) => {
      dispatch(userActions.setUserInfo(data));
      localStorage.setItem("account", JSON.stringify(data));
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (userState.userInfo) {
      navigate("/");
    }
  }, [navigate, userState.userInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      hCaptchaToken: "",
      hCaptchaEkey: "",
    },
    mode: "onChange",
  });

  const submitHandler = (data) => {
    if (!hCaptchaEkey || !hCaptchaToken) {
      return false;
    }

    const { name, email, password } = data;
    mutate({ name, email, password, hCaptchaToken, hCaptchaEkey });
  };
  const password = watch("password");

  const handleVerificationSuccess = (token, ekey) => {
    setHCaptchaToken(token);
    sethCaptchaEkey(ekey);
  };

  return (
    <MainLayout>
      <section className="container mx-auto px-5 py-10">
        <div className="mx-auto w-full max-w-sm">
          <h1 className="font-roboto mb-8 text-center text-2xl font-bold text-dark-hard">
            Sign Up
          </h1>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="mb-6 flex w-full flex-col">
              <label htmlFor="name" className="font-semibold text-dark-hard">
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", {
                  minLength: {
                    value: 1,
                    message: "Name length must be at least 1 character",
                  },
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                })}
                placeholder="Enter name"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.name ? "border-red-500" : ""
                }`}
              />
              {errors.name?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.name.message}
                </p>
              )}
            </div>

            <div className="mb-6 flex w-full flex-col">
              <label htmlFor="email" className="font-semibold text-dark-hard">
                Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email", {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                })}
                placeholder="Enter email"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
              {errors.email?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.email.message}
                </p>
              )}
            </div>
            <div className="mb-6 flex w-full flex-col">
              <label
                htmlFor="password"
                className="font-semibold text-dark-hard"
              >
                Password (min 6 character)
              </label>
              <input
                type="password"
                id="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  minLength: {
                    value: 6,
                    message: "Password length must be atleast 6 characters",
                  },
                })}
                placeholder="Enter password"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.password ? "border-red-500" : ""
                }`}
              />
              {errors.password?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.password.message}
                </p>
              )}
            </div>
            <div className="mb-6 flex w-full flex-col">
              <label
                htmlFor="confirmPassword"
                className="font-semibold text-dark-hard"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                {...register("confirmPassword", {
                  required: {
                    value: true,
                    message: "Confirm Password is required",
                  },
                  validate: (value) => {
                    if (value !== password) return "Passwords do not match";
                  },
                })}
                placeholder="Enter password again"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.confirmPassword ? "border-red-500" : ""
                }`}
              />
              {errors.confirmPassword?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.confirmPassword.message}
                </p>
              )}
            </div>
            <div className="mb-6 mt-6 ">
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITKEY}
                onVerify={(token, ekey) =>
                  handleVerificationSuccess(token, ekey)
                }
              />
            </div>
            <button
              type="submit"
              disabled={
                !isValid || isLoading || !hCaptchaEkey || !hCaptchaToken
              }
              className="mb-6 w-full rounded-lg bg-primary px-8 py-4 text-lg font-bold text-white disabled:cursor-not-allowed disabled:opacity-30"
            >
              Register
            </button>
            <p className="text-sm font-semibold text-dark-light">
              You have an account?{" "}
              <Link to="/login" className="text-primary">
                Login Now
              </Link>
            </p>
          </form>
        </div>
      </section>
    </MainLayout>
  );
}
