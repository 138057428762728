import { useCallback } from "react";
import {
  AiOutlineBold,
  AiOutlineClose,
  AiOutlineEnter,
  AiOutlineItalic,
  AiOutlineOrderedList,
  AiOutlineRedo,
  AiOutlineStrikethrough,
  AiOutlineUndo,
  AiOutlineUnorderedList,
  AiOutlineCode,
  AiOutlineComment,
  AiOutlinePicture,
} from "react-icons/ai";
import { BiParagraph } from "react-icons/bi";
import { FiCode } from "react-icons/fi";
import { MdOutlineLayersClear } from "react-icons/md";
import { TbSpacingVertical } from "react-icons/tb";

const buttonStyle =
  "flex justify-center items-center text-slate-700 rounded-lg w-8 aspect-square";
const buttonStyleActive = "text-slate-800 bg-slate-200";

export const MenuBar = ({ editor }) => {
  const addImage = useCallback(() => {
    const url = window.prompt("URL");

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="sticky left-0 right-0 top-3 z-10 flex flex-wrap gap-0.5 rounded-lg border border-slate-300 bg-white p-5">
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`${buttonStyle} font-black ${
          editor.isActive("heading", { level: 1 }) && buttonStyleActive
        }`}
      >
        H1
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`${buttonStyle}  font-extrabold ${
          editor.isActive("heading", { level: 2 }) && buttonStyleActive
        }`}
      >
        H2
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={`${buttonStyle}  font-semibold ${
          editor.isActive("heading", { level: 3 }) && buttonStyleActive
        }`}
      >
        H3
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={`${buttonStyle}  font-medium ${
          editor.isActive("heading", { level: 4 }) && buttonStyleActive
        }`}
      >
        H4
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={`${buttonStyle}  font-normal ${
          editor.isActive("heading", { level: 5 }) && buttonStyleActive
        }`}
      >
        H5
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={`${buttonStyle}  font-normal ${
          editor.isActive("heading", { level: 6 }) && buttonStyleActive
        }`}
      >
        H6
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={`${buttonStyle}  ${
          editor.isActive("bold") && buttonStyleActive
        }`}
      >
        <AiOutlineBold />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={`${buttonStyle}  ${
          editor.isActive("italic") && buttonStyleActive
        }`}
      >
        <AiOutlineItalic />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={`${buttonStyle}  ${
          editor.isActive("strike") && buttonStyleActive
        }`}
      >
        <AiOutlineStrikethrough />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={`${buttonStyle}  ${
          editor.isActive("code") && buttonStyleActive
        }`}
      >
        <FiCode />
      </button>
      <button
        onClick={() => editor.chain().focus().unsetAllMarks().run()}
        className={`${buttonStyle} `}
      >
        <MdOutlineLayersClear />
      </button>
      <button
        onClick={() => editor.chain().focus().clearNodes().run()}
        className={`${buttonStyle} `}
      >
        <AiOutlineClose />
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`${buttonStyle}  ${
          editor.isActive("paragraph") && buttonStyleActive
        }`}
      >
        <BiParagraph />
      </button>
      <button
        onClick={addImage}
        className={`${buttonStyle}  ${
          editor.isActive("paragraph") && buttonStyleActive
        }`}
      >
        <AiOutlinePicture />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${buttonStyle}  ${
          editor.isActive("bulletList") && buttonStyleActive
        }`}
      >
        <AiOutlineUnorderedList />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${buttonStyle}  ${
          editor.isActive("orderedList") && "active-${buttonStyle} "
        }`}
      >
        <AiOutlineOrderedList />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={`${buttonStyle}  ${
          editor.isActive("codeBlock") && "active-${buttonStyle} "
        }`}
      >
        <AiOutlineCode />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`${buttonStyle}  ${
          editor.isActive("blockquote") && "active-${buttonStyle} "
        }`}
      >
        <AiOutlineComment />
      </button>
      <button
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        className={`${buttonStyle} `}
      >
        <TbSpacingVertical />
      </button>
      <button
        onClick={() => editor.chain().focus().setHardBreak().run()}
        className={`${buttonStyle} `}
      >
        <AiOutlineEnter />
      </button>
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        className={`${buttonStyle} `}
      >
        <AiOutlineUndo />
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        className={`${buttonStyle} `}
      >
        <AiOutlineRedo />
      </button>
    </div>
  );
};
