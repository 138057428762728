import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { forgotUserPassword } from "../../services/index/users";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import HCaptcha from "@hcaptcha/react-hcaptcha";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const [hCaptchaToken, setHCaptchaToken] = useState("");
  const [hCaptchaEkey, sethCaptchaEkey] = useState("");
  const { mutate, isLoading } = useMutation({
    mutationFn: ({ email, hCaptchaToken, hCaptchaEkey }) => {
      return forgotUserPassword({ email, hCaptchaToken, hCaptchaEkey });
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (userState.userInfo) {
      navigate("/");
    }
  }, [navigate, userState.userInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: "",
      hCaptchaToken: "",
      hCaptchaEkey: "",
    },
    mode: "onChange",
  });

  const submitHandler = (data) => {
    const { email } = data;
    mutate({ email, hCaptchaToken, hCaptchaEkey });
    navigate("/login");
  };

  const handleVerificationSuccess = (token, ekey) => {
    setHCaptchaToken(token);
    sethCaptchaEkey(ekey);
  };

  return (
    <MainLayout>
      <section className="container mx-auto min-h-[30rem] px-5 py-10">
        <div className="mx-auto w-full max-w-sm">
          <h1 className="font-roboto mb-8 text-center text-2xl font-bold text-dark-hard">
            Forgot Password
          </h1>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="mb-6 flex w-full flex-col">
              <label htmlFor="email" className="font-semibold text-dark-hard">
                Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email", {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                })}
                placeholder="Enter email"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
              <p className="text-sm font-semibold text-dark-light">
                Enter your email and then you should get a password reset link
              </p>
              {errors.email?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.email.message}
                </p>
              )}
            </div>
            <div className="mb-6 mt-6 ">
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_SITKEY}
                onVerify={(token, ekey) =>
                  handleVerificationSuccess(token, ekey)
                }
              />
            </div>
            <div className="mb-6 flex w-full flex-col">
              <button
                type="submit"
                disabled={
                  !isValid || isLoading || !hCaptchaEkey || !hCaptchaToken
                }
                className="my-6 w-full rounded-lg bg-primary px-8 py-4 text-lg font-bold text-white disabled:cursor-not-allowed disabled:opacity-30"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </MainLayout>
  );
}
