import React, { useEffect, useMemo } from "react";
import MainLayout from "../../components/MainLayout";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { userActions } from "../../store/reducers/userReducer";
import {
  createNewEmailVerificationCode,
  getUserProfile,
  updateProfile,
  verifyEmailVerificationCode,
} from "../../services/index/users";
import ProfilePicture from "../../components/ProfilePicture";
import toast from "react-hot-toast";
import { CodeInput } from "../../components/CodeInput";

export default function ProfilePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const userState = useSelector((state) => state.user);
  // useEffect if not logged in
  useEffect(() => {
    if (!userState.userInfo) {
      navigate("/");
    }
  }, [navigate, userState.userInfo]);

  // query/mutation
  const {
    data: profileData,
    isLoading: profileIsLoading,
    error: profileError,
  } = useQuery({
    queryFn: () => {
      return getUserProfile({ token: userState.userInfo.token });
    },
    // queryKey is for caching
    queryKey: ["profile"],
  });

  const { mutate, updateProfileIsLoading } = useMutation({
    mutationFn: ({ name, email, password, bio }) => {
      return updateProfile({
        token: userState.userInfo.token,
        userData: { name, email, password, bio },
        userId: userState.userInfo._id,
      });
    },
    onSuccess: (data) => {
      dispatch(userActions.setUserInfo(data));
      localStorage.setItem("account", JSON.stringify(data));
      // invalidate the cached 'profile' and refetch
      // but will not refetch others
      queryClient.invalidateQueries(["profile"]);
      toast.success("Profile is updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const { mutate: mutateCreateNewEmailVerificationCode } = useMutation({
    mutationFn: () => {
      return createNewEmailVerificationCode({
        token: userState.userInfo.token,
      });
    },
    onSuccess: () => {
      toast.success("New verification code has been sent- check your email");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const { mutate: mutateSubmitVerificationCode } = useMutation({
    mutationFn: ({ otp }) => {
      return verifyEmailVerificationCode({
        token: userState.userInfo.token,
        otp,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["profile"]);
      dispatch(userActions.setUserInfo(data));
      toast.success("Verification has been accepted");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const newVerificationCodeHandler = () => {
    mutateCreateNewEmailVerificationCode();
  };

  const onVerificationCodeSubmit = (data) => {
    const { code1, code2, code3, code4 } = data;
    mutateSubmitVerificationCode({ otp: `${code1}${code2}${code3}${code4}` });
  };

  // onSubmit logic
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      bio: "",
    },
    values: useMemo(() => {
      return {
        name: profileIsLoading
          ? ""
          : profileData?.name || userState.userInfo.name,
        email: profileIsLoading
          ? ""
          : profileData?.email || userState.userInfo.email,
        bio: profileIsLoading ? "" : profileData?.bio || userState.userInfo.bio,
      };
    }, [
      profileData?.email,
      profileData?.bio,
      profileData?.name,
      profileIsLoading,
    ]),
    mode: "onChange",
  });

  const submitHandler = (data) => {
    const { name, email, password, bio } = data;
    mutate({ name, email, password, bio });
  };

  return (
    <MainLayout>
      <section className="container mx-auto px-5 py-10">
        <div className="mx-auto w-full max-w-sm">
          {!userState?.userInfo?.verified && (
            <div className="mb-6 flex w-full flex-col justify-center rounded-xl border-2 border-solid border-gray-600 bg-gray-200 p-4 align-middle">
              <label
                htmlFor="name"
                className="mb-3 text-center font-semibold text-dark-hard"
              >
                Verify your email
              </label>
              <CodeInput submitHandler={onVerificationCodeSubmit} />
              <button onClick={newVerificationCodeHandler}>
                <p className=" mb-6 w-full rounded-lg bg-gray-400 px-8 py-4 text-lg font-bold text-white hover:bg-gray-500 disabled:cursor-not-allowed disabled:opacity-30">
                  Generate new verification code
                </p>
              </button>
            </div>
          )}
          <ProfilePicture avatar={profileData?.avatar} />
          <form onSubmit={handleSubmit(submitHandler)}>
            {/* Name */}
            <div className="mb-6 flex w-full flex-col">
              <label htmlFor="name" className="font-semibold text-dark-hard">
                Name
              </label>
              <input
                type="text"
                id="name"
                {...register("name", {
                  minLength: {
                    value: 1,
                    message: "Name length must be at least 1 character",
                  },
                  required: {
                    value: true,
                    message: "Name is required",
                  },
                })}
                placeholder="Enter name"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.name ? "border-red-500" : ""
                }`}
              />
              {errors.name?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.name.message}
                </p>
              )}
            </div>
            {/* Email */}
            <div className="mb-6 flex w-full flex-col">
              <label htmlFor="email" className="font-semibold text-dark-hard">
                Email
              </label>
              <input
                type="email"
                id="email"
                {...register("email", {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email address",
                  },
                  required: {
                    value: true,
                    message: "Email is required",
                  },
                })}
                placeholder="Enter email"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
              {errors.email?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.email.message}
                </p>
              )}
            </div>
            {/* Password */}
            <div className="mb-6 flex w-full flex-col">
              <label
                htmlFor="password"
                className="font-semibold text-dark-hard"
              >
                New Password (optional)
              </label>
              <input
                type="password"
                id="password"
                placeholder="Enter new password"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.password ? "border-red-500" : ""
                }`}
              />
              {errors.password?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.password.message}
                </p>
              )}
            </div>
            {/* Bio */}
            <div className="mb-6 flex w-full flex-col">
              <label htmlFor="name" className="font-semibold text-dark-hard">
                Bio
              </label>
              <textarea
                type="bio"
                id="bio"
                {...register("bio", {})}
                placeholder="Enter Bio"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.name ? "border-red-500" : ""
                }`}
              />
            </div>
            <button
              type="submit"
              disabled={!isValid || profileIsLoading || updateProfileIsLoading}
              className="mb-6 w-full rounded-lg bg-primary px-8 py-4 text-lg font-bold text-white disabled:cursor-not-allowed disabled:opacity-30"
            >
              Update
            </button>
          </form>
        </div>
      </section>
    </MainLayout>
  );
}
