import React from "react";
import { useForm } from "react-hook-form";

export const CodeInput = ({ submitHandler }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <form class="mx-auto max-w-sm" onSubmit={handleSubmit(submitHandler)}>
      <div class="mb-2 flex justify-center space-x-2 rtl:space-x-reverse">
        <div>
          <label for="code1" class="sr-only">
            First code
          </label>
          <input
            {...register("code1", {
              valueAsNumber: true,
              pattern: {
                value: /^[0-9]+$/,
                message: "Please enter a number",
              },
              maxLength: {
                value: 1,
              },
              required: {
                value: true,
                message: "Number is required",
              },
            })}
            type="text"
            maxlength="1"
            id="code1"
            class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block h-9 w-9 rounded-lg border border-gray-300 bg-white py-3 text-center text-sm font-extrabold text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
          />
        </div>
        <div>
          <label for="code2" class="sr-only">
            Second code
          </label>
          <input
            {...register("code2", {
              valueAsNumber: true,
              pattern: {
                value: /^[0-9]+$/,
                message: "Please enter a number",
              },
              maxLength: {
                value: 1,
              },
              required: {
                value: true,
                message: "Number is required",
              },
            })}
            type="text"
            maxlength="1"
            id="code2"
            class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block h-9 w-9 rounded-lg border border-gray-300 bg-white py-3 text-center text-sm font-extrabold text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
          />
        </div>
        <div>
          <label for="code3" class="sr-only">
            Third code
          </label>
          <input
            {...register("code3", {
              valueAsNumber: true,
              pattern: {
                value: /^[0-9]+$/,
                message: "Please enter a number",
              },
              maxLength: {
                value: 1,
              },
              required: {
                value: true,
                message: "Number is required",
              },
            })}
            type="text"
            maxlength="1"
            id="code3"
            class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block h-9 w-9 rounded-lg border border-gray-300 bg-white py-3 text-center text-sm font-extrabold text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
          />
        </div>
        <div>
          <label for="code4" class="sr-only">
            Fourth code
          </label>
          <input
            {...register("code4", {
              valueAsNumber: true,
              pattern: {
                value: /^[0-9]+$/,
                message: "Please enter a number",
              },
              maxLength: {
                value: 1,
              },
              required: {
                value: true,
                message: "Number is required",
              },
            })}
            type="text"
            maxlength="1"
            id="code4"
            class="focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 block h-9 w-9 rounded-lg border border-gray-300 bg-white py-3 text-center text-sm font-extrabold text-gray-900 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400"
          />
        </div>
      </div>
      <p
        id="helper-text-explanation"
        class="mt-2 pb-4 text-sm text-gray-500 dark:text-gray-400"
      >
        Please enter the 4 digit code we sent via email.
      </p>
      <button
        type="submit"
        className="mb-6 w-full rounded-lg bg-primary px-8 py-4 text-lg font-bold text-white hover:bg-orange-600 disabled:cursor-not-allowed disabled:opacity-30"
      >
        Submit Code
      </button>
    </form>
  );
};
