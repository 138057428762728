import "./input.css";
import BlogPost from "./pages/blogPost/BlogPost";
import Home from "./pages/home/Home";
import { Routes, Route } from "react-router-dom";
import RegisterPage from "./pages/register/RegisterPage";
import LoginPage from "./pages/login/LoginPage";
import { Toaster } from "react-hot-toast";
import ProfilePage from "./pages/profile/ProfilePage";
import { AdminLayout } from "./pages/admin/AdminLayout";
import { Admin } from "./pages/admin/screens/Admin";
import { AdminComments } from "./pages/admin/screens/adminComments/AdminComments";
import { AdminNewPost } from "./pages/admin/screens/posts/AdminNewPost";
import { AdminManagePosts } from "./pages/admin/screens/posts/AdminManagePosts";
import { AdminEditPost } from "./pages/admin/screens/posts/AdminEditPost";
import { AdminCategories } from "./pages/admin/screens/categories/AdminCategories";
import { AdminEditCategories } from "./pages/admin/screens/categories/AdminEditCategories";
import { AdminUser } from "./pages/admin/screens/users/AdminUser";
import { PostsPage } from "./pages/posts/PostsPage";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage";
import ResetPasswordPage from "./pages/login/ResetPasswordPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/post/:slug" element={<BlogPost />} />
        <Route path="/post" element={<PostsPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/admin" element={<AdminLayout />}>
          {/* outlet component within AdminLayout will render the ones inside */}
          <Route index element={<Admin />} />
          <Route path="comments" element={<AdminComments />} />
          <Route path="posts/new" element={<AdminNewPost />} />
          <Route path="posts/manage" element={<AdminManagePosts />} />
          <Route path="posts/manage/edit/:slug" element={<AdminEditPost />} />
          <Route path="categories/manage" element={<AdminCategories />} />
          <Route
            path="categories/manage/edit/:slug"
            element={<AdminEditCategories />}
          />
          <Route path="users/manage" element={<AdminUser />} />
        </Route>
      </Routes>
      <Toaster />
    </div>
  );
}

export default App;
