import React from "react";
import { images } from "../constants";

export default function Footer() {
  return (
    <section className=" bg-dark-soft">
      <footer className="container mx-auto flex justify-center gap-x-5 gap-y-10 px-5 py-10 align-middle lg:grid lg:grid-cols-10">
        <div className="col-span-5">
          <div>
            <img
              className="h-auto w-28 object-scale-down"
              src={images.logo}
              alt="logo"
            />
          </div>
          <ul className="mt-5 space-y-4 text-center text-sm  text-white md:text-left">
            <div>
              <a href="/">Sign In</a>
            </div>
            <div>
              <a href="/">Posts</a>
            </div>
            <div>
              <a href="/">About Me</a>
            </div>
            <div>
              <a href="/">Other links</a>
            </div>
          </ul>
        </div>
      </footer>
    </section>
  );
}
