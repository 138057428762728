import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import Breadcrumbs from "../../components/Breadcrumbs";
import CommentsContainer from "../../components/comments/CommentsContainer";
import SuggestedPosts from "./container/SuggestedPosts";
import Social from "../../components/Social";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSinglePost, getThreeLatestPosts } from "../../services/index/posts";
import { BlogSkeleton } from "./components/BlogSkeleton";
import { Error } from "../../components/Error";
import { useSelector } from "react-redux";
import { parseJSONtoHTML } from "../../utils/parseJSONtoHTML";
import { Editor } from "../../components/editor/Editor";

export default function BlogPost() {
  const { slug } = useParams();
  const userState = useSelector((state) => state.user);
  const [breadCrumsbsData, setBreadCrumbsData] = useState([]);
  const [body, setBody] = useState(null);

  const { data, isLoading, isError } = useQuery({
    queryFn: () => getSinglePost({ slug }),
    queryKey: ["post", slug],
  });

  // works kind of like a useEffect, but not exactly
  if (!isLoading && data?.slug && breadCrumsbsData.length === 0) {
    setBreadCrumbsData([
      { name: "Home", link: "/" },
      { name: "Posts", link: "/post" },
      { name: `${data.title}`, link: `/post/${data.slug}` },
    ]);

    setBody(parseJSONtoHTML(data?.body));
  }

  const { data: recommendedPostsData } = useQuery({
    queryFn: () => getThreeLatestPosts(),
    queryKey: ["threePosts"],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainLayout>
      {isLoading ? (
        <BlogSkeleton />
      ) : !isError ? (
        <section className="max-w-5-xl container mx-auto flex flex-col px-5 py-5 lg:flex-row lg:items-start lg:gap-x-5">
          <article className="flex-1">
            <Breadcrumbs data={breadCrumsbsData} />
            <div className="flex max-h-96 justify-center">
              <img
                className="w-full rounded-xl object-contain"
                src={data?.imageURL || null}
                alt={data?.title}
              />
            </div>
            <div className="mt-4 flex gap-2">
              {data?.categories.map((category) => {
                return (
                  <Link
                    to={`posts?category=${category.name}`}
                    className="text-md font-md text-dark-hard"
                  >
                    {category.name}
                  </Link>
                );
              })}
            </div>
            <h1 className="md:3xl mt-4 inline-block px-5 text-3xl text-primary">
              {data?.title}
            </h1>
            <h1 className="md:3xl mt-1 px-5 text-xl text-dark-light">
              {data?.caption}
            </h1>
            <div className="w-full">
              {!isLoading && !isError && (
                <Editor content={data?.body} editable={false} />
              )}
            </div>
            <CommentsContainer
              postSlug={slug}
              comments={data?.comments}
              className={"mt-10"}
              userLoggedInId={userState?.userInfo?._id}
            />
          </article>
          <div className="sticky top-48">
            <SuggestedPosts
              tags={data?.tags}
              title="Other Posts"
              posts={recommendedPostsData?.data}
              className={"mt-8 lg:mt-0 lg:max-w-xs"}
            />
            <div className="mt-7">
              <Social />
            </div>
          </div>
        </section>
      ) : (
        <Error message={"Couldn't fetch post details"} />
      )}
    </MainLayout>
  );
}
