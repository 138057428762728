import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";

export const Search = ({ className, onSearchKeyword }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsValue = Object.fromEntries([...searchParams]);
  const defaultSearchKeyword = searchParamsValue?.search || "";
  const [searchKeyword, setSearchKeyword] = useState(defaultSearchKeyword);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearchKeyword({ searchKeyword });
  };

  return (
    <form onSubmit={handleSubmit} className={`relative ${className}`}>
      <div className="relative">
        <AiOutlineSearch className="absolute left-3 top-1/2 h-6 w-6 -translate-y-1/2 text-dark-hard" />
        <input
          // tailwindcss shadow from: https://manuarora.in/boxshadows
          type="text"
          className="w-full rounded-lg py-3 pl-12 pr-3 font-semibold text-dark-soft shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)] placeholder:font-bold focus:outline-none md:py-4"
          placeholder="Search..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </div>
      <button
        type="submit"
        className="w-full rounded-lg bg-dark-soft px-5 py-3 font-semibold text-white md:absolute md:right-2 md:top-1/2 md:w-fit md:-translate-y-1/2 md:py-2"
      >
        Search
      </button>
    </form>
  );
};
