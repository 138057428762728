import React from "react";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillRedditSquare,
  AiFillInstagram,
} from "react-icons/ai";

export default function Social() {
  return (
    <>
      <h2 className="text-xl text-dark-hard mb:4 mb:text-xl">
        {"Feel free to share!"}
      </h2>
      <div className="w-full flex justify-between">
        <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
          <AiFillFacebook className="w-12 h-auto" />
        </a>
        <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
          <AiFillInstagram className="w-12 h-auto" />
        </a>
        <a href="https://twitter.com/?lang=en" target="_blank" rel="noreferrer">
          <AiFillTwitterSquare className="w-12 h-auto" />
        </a>
        <a href="https://www.reddit.com/" target="_blank" rel="noreferrer">
          <AiFillRedditSquare className="w-12 h-auto" />
        </a>
      </div>
    </>
  );
}
