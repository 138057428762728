import React from "react";
import { Link } from "react-router-dom";
import { useDataTable } from "../../../../hooks/useDataTable";
import { DataTable } from "../../components/DataTable";
import { formatDate } from "../../../../utils/dateFormatter";
import {
  deleteUser,
  getAllUsers,
  updateProfile,
} from "../../../../services/index/users";
import { AiFillCheckCircle, AiFillCiCircle } from "react-icons/ai";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

export const AdminUser = () => {
  const queryClient = useQueryClient();

  const {
    userState,
    currentPage,
    searchKeyword,
    data: usersData,
    isLoading,
    isFetching,
    isLoadingDeleteData,
    searchKeywordHandler,
    submitSearchKeyWordHandler,
    deleteDataHandler,
    setCurrentPage,
  } = useDataTable({
    dataQueryFunction: () =>
      getAllUsers(userState.userInfo.token, searchKeyword, currentPage),
    dataQueryKey: "users",
    deleteDataMessage: "User has been deleted",
    mutateDeleteFunction: ({ slug, token }) => {
      return deleteUser({ slug, token });
    },
  });

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
    useMutation({
      mutationFn: ({ isAdmin, userId }) => {
        return updateProfile({
          token: userState.userInfo.token,
          userData: {
            admin: isAdmin,
          },
          userId,
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
        toast.success("Updated user");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const handleAdminCheck = (event, userId) => {
    const initialCheckValue = !event.target.checked;

    if (
      window.confirm("Do you want to change the admin status of this user?")
    ) {
      mutateUpdateUser({ isAdmin: event.target.checked, userId });
    } else {
      event.target.checked = initialCheckValue;
    }
  };

  return (
    <DataTable
      pageTitle="Manage Users"
      dataListName="Users"
      searchInputPlaceHolder="User email"
      searchKeyWordOnSubmitHandler={submitSearchKeyWordHandler}
      searchKeyWordOnChangeHandler={searchKeywordHandler}
      searchKeyword={searchKeyword}
      tableHeaderTitleList={[
        "Name",
        "Email",
        "Created At",
        "isVerified",
        "isAdmin",
        "",
      ]}
      isLoading={isLoading}
      isFetching={isFetching}
      data={usersData}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      headers={usersData?.headers}
    >
      {usersData?.data?.map((user) => {
        return (
          <tr key={user._id}>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    alt={user.name}
                    src={user.imageURL || null}
                    className="mx-auto h-10 w-10 rounded-lg object-cover "
                  />
                </div>
                <div className="ml-3">
                  <p className="whitespace-no-wrap text-gray-900">
                    {user.name}
                  </p>
                </div>
              </div>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap text-gray-900">{user.email}</p>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap text-gray-900">
                {formatDate(user.createdAt)}
              </p>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-2xl">
              {user.verified && <AiFillCheckCircle />}
            </td>
            <td className="border-b  border-gray-200 bg-white px-5 py-5 text-2xl">
              <input
                type="checkbox"
                className=" h-5 w-5 text-white accent-primary disabled:opacity-50 "
                disabled={
                  isLoadingUpdateUser || user._id === userState.userInfo._id
                }
                defaultChecked={user.admin}
                onChange={(event) => handleAdminCheck(event, user._id)}
              />
            </td>
            <td className="space-x-5 border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <button
                disabled={isLoadingDeleteData}
                type="button"
                className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed disabled:opacity-70"
                onClick={() =>
                  deleteDataHandler({
                    slug: user?._id,
                    token: userState.userInfo.token,
                  })
                }
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
};
