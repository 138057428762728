import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getAllPosts } from "../../services/index/posts";
import BlogCard from "../../components/BlogCard";
import BlogCardSkeleton from "../../components/BlogCardSkeleton";
import { Error } from "../../components/Error";
import MainLayout from "../../components/MainLayout";
import { Pagination } from "../admin/components/header/Pagination";
import { useSearchParams } from "react-router-dom";
import { Search } from "../../components/Search";

export const PostsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsValue = Object.fromEntries([...searchParams]);
  const currentPage = parseInt(searchParamsValue?.page) || 1;
  const searchKeyword = searchParamsValue?.search || "";

  const { data, isLoading, isError, error, refetch, isFetching } = useQuery({
    queryFn: () => getAllPosts(searchKeyword, currentPage, 12),
    queryKey: ["posts"],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isError) {
      toast.error(error.message);
    }
    refetch();
  }, [currentPage, isError, searchKeyword]);

  const handlePageChange = (page) => {
    // to keep pagination page state when refreshing
    setSearchParams({ page, search: searchKeyword });
  };

  const handleSearch = ({ searchKeyword }) => {
    setSearchParams({ page: 1, search: searchKeyword });
  };
  console.log("isLoading", isLoading);
  console.log("data?.headers", data?.headers);
  console.log(
    'data?.headers?.["x-totalpagecount"]',
    data?.headers?.["x-totalpagecount"],
  );
  return (
    <MainLayout>
      <section className="container mx-auto flex flex-col px-5 py-10">
        <Search
          className={"mb-10 w-full max-w-2xl"}
          onSearchKeyword={handleSearch}
        />
        <div className="flex flex-wrap gap-y-5 pb-10 md:gap-x-5">
          {isLoading || isFetching ? (
            [...Array(12)].map((item, index) => (
              <BlogCardSkeleton
                key={index}
                className={
                  "w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
                }
              />
            ))
          ) : !isError ? (
            data?.data.length === 0 ? (
              <p className="text-primary">No results</p>
            ) : (
              data?.data.map((post) => {
                return (
                  <BlogCard
                    key={post._id}
                    post={post}
                    className={
                      "w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
                    }
                  />
                );
              })
            )
          ) : (
            <Error message={"Could not fetch data"} />
          )}
        </div>
        {!isLoading && data?.headers && (
          <Pagination
            onPageChange={(page) => handlePageChange(page)}
            currentPage={currentPage}
            totalPageCount={JSON.parse(data?.headers?.["x-totalpagecount"])}
          />
        )}
      </section>
    </MainLayout>
  );
};
