import React from "react";
import {
  editComment,
  getAllComments,
  removeComment,
} from "../../../../services/index/comments";
import { useDataTable } from "../../../../hooks/useDataTable";
import { DataTable } from "../../components/DataTable";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/dateFormatter";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

export const AdminComments = () => {
  const queryClient = useQueryClient();
  const {
    userState,
    currentPage,
    searchKeyword,
    data: commentsData,
    isLoading,
    isFetching,
    isLoadingDeleteData,
    searchKeywordHandler,
    submitSearchKeyWordHandler,
    deleteDataHandler,
    setCurrentPage,
  } = useDataTable({
    dataQueryFunction: () =>
      getAllComments({
        token: userState.userInfo.token,
        searchKeyword,
        currentPage,
      }),
    dataQueryKey: "comments",
    deleteDataMessage: "Comment has been deleted",
    mutateDeleteFunction: ({ slug, token }) => {
      return removeComment({
        commentId: slug,
        token,
      });
    },
  });

  const {
    mutate: mutateUpdateCommentCheck,
    isLoading: isLoadingUpdateCommentCheck,
  } = useMutation({
    mutationFn: ({ token, check, commentId }) => {
      return editComment({ token, check, commentId });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["comments"]);
      toast.success(
        data?.check ? "Comment is approved" : "Comment is not approved",
      );
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return (
    <div>
      <DataTable
        pageTitle={"Manage Comments"}
        dataListName={"Comments"}
        searchInputPlaceHolder={"Search Comments"}
        searchKeyWordOnSubmitHandler={submitSearchKeyWordHandler}
        searchKeyWordOnChangeHandler={searchKeywordHandler}
        searchKeyword={searchKeyword}
        tableHeaderTitleList={["Author", "Comment", "Post", "CreatedAt", ""]}
        isFetching={isFetching}
        isLoading={isLoading}
        data={commentsData?.data}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        headers={commentsData?.headers}
      >
        {commentsData?.data?.map((comment) => {
          return (
            <tr key={comment._id}>
              <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      alt={comment?.user?.name}
                      src={comment?.user?.imageURL || null}
                      className="mx-auto h-10 w-10 rounded-lg object-cover "
                    />
                  </div>
                  <div className="ml-3">
                    <p className="whitespace-no-wrap text-gray-900">
                      {comment.user.name}
                    </p>
                  </div>
                </div>
              </td>
              <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                {comment?.replyOnUser && (
                  <p className="whitespace-no-wrap text-gray-900">
                    In reply to{": "}
                    <Link
                      to={`/post/${comment?.post?.slug}/#comment-${comment?._id}`}
                      className="text-blue-500"
                    >
                      {comment?.replyOnUser?.name}
                    </Link>
                  </p>
                )}
                <p className="whitespace-no-wrap text-gray-900">
                  {comment?.desc}
                </p>
              </td>
              <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                <Link
                  to={`/post/${comment?.post?.slug}`}
                  className="text-blue-500"
                >
                  {comment?.post?.title}
                </Link>
              </td>
              <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                <p>{formatDate(comment.createdAt)}</p>
              </td>
              <td className="space-x-5 border-b border-gray-200 bg-white px-5 py-5 text-sm">
                <button
                  disabled={isLoadingDeleteData}
                  type="button"
                  className={`${comment?.check ? "text-yellow-600 hover:text-yellow-900" : "text-green-600 hover:text-green-900"}disabled:cursor-not-allowed disabled:opacity-70`}
                  onClick={() =>
                    mutateUpdateCommentCheck({
                      commentId: comment?._id,
                      check: comment?.check ? false : true,
                      token: userState.userInfo.token,
                    })
                  }
                >
                  {comment?.check ? "Unapprove" : "Approve"}
                </button>
                <button
                  disabled={isLoadingDeleteData}
                  type="button"
                  className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed disabled:opacity-70"
                  onClick={() =>
                    deleteDataHandler({
                      slug: comment?._id,
                      token: userState.userInfo.token,
                    })
                  }
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })}
      </DataTable>
    </div>
  );
};
