import React, { useEffect, useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import { CropEasy } from "./crop/CropEasy";
import { createPortal } from "react-dom";
import { useQuery } from "@tanstack/react-query";
import {
  deleteProfilePicutre,
  getUserProfilePicture,
} from "../services/index/users";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function ProfilePicture() {
  const queryClient = useQueryClient();
  const [openCrop, setOpenCrop] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(null);
  const userState = useSelector((state) => state.user);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto({ url: URL.createObjectURL(file), file });
    setOpenCrop(true);
  };

  // delete profile picture
  const { mutate, isLoading } = useMutation({
    mutationFn: ({ token, formData }) => {
      return deleteProfilePicutre({
        token: token,
      });
    },
    onSuccess: (data) => {
      setOpenCrop(false);
      localStorage.setItem("account", JSON.stringify(data));
      queryClient.invalidateQueries(["profile"]);
      toast.success("Profile Photo is deleted");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleDeleteImage = async () => {
    if (window.confirm("Do you want to delete your profile picture?"))
      try {
        mutate({ token: userState.userInfo.token });
        setPhotoUrl(null);
      } catch (error) {
        toast.error(error.message);
      }
  };
  // userState.userInfo.avatar
  // fetch profile picture
  const { data: profilePictureData } = useQuery({
    queryFn: () => {
      return getUserProfilePicture({ token: userState.userInfo.token });
    },
    // queryKey is for caching
    queryKey: ["profile"],
  });

  useEffect(
    () => setPhotoUrl(profilePictureData?.avatarURL),
    [profilePictureData],
  );

  return (
    <>
      {openCrop &&
        createPortal(
          <CropEasy photo={photo} setOpenCrop={setOpenCrop} />,
          document.getElementById("portal"),
        )}
      <div className="mb-4 flex w-full flex-col items-center justify-center gap-y-2.5 ">
        <div className="relative h-20 w-20 overflow-hidden rounded-full border-gray-200 outline outline-offset-2 outline-gray-200">
          <label
            htmlFor="profilePicture"
            className="absolute inset-0 cursor-pointer rounded-full bg-transparent"
          >
            {photoUrl ? (
              <img
                src={photoUrl}
                alt="profile-avatar"
                className="h-full w-full object-cover"
              />
            ) : (
              <div className="flex h-full w-full flex-col items-center justify-center bg-gray-200/50">
                <AiOutlineCamera className="h-auto w-7 text-gray-500" />
                Upload
              </div>
            )}
          </label>
          <input
            type="file"
            className="sr-only"
            id="profilePicture"
            onChange={handleFileChange}
          />
        </div>
        <button
          onClick={handleDeleteImage}
          type="button"
          className="rounded-lg border border-red-500 px-4 py-2 text-red-500"
        >
          Delete
        </button>
      </div>
    </>
  );
}
