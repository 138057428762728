import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import {
  login,
  resetUserPassword,
  verifyTokenCode,
} from "../../services/index/users";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/reducers/userReducer";

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const [validToken, setValidToken] = useState(true);
  const userState = useSelector((state) => state.user);
  const token = searchParams.get("token");
  const id = searchParams.get("id");

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ password, confirmPassword }) => {
      return resetUserPassword({ token, id, password, confirmPassword });
    },
    onSuccess: () => {
      navigate("/login");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      password: "",
    },
    mode: "onChange",
  });

  const submitHandler = (data) => {
    const { password, confirmPassword } = data;
    mutate({ password, confirmPassword });
  };

  const verifyToken = async () => {
    const { success } = await verifyTokenCode({ token, id });
    if (!success) {
      setValidToken(false);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const password = watch("password");

  if (!id || !token || !validToken)
    return (
      <MainLayout>
        <div className="m-auto max-w-screen-sm pt-40">
          <h1 className="mb-3 text-center text-3xl text-gray-500">
            Invalid URL
          </h1>
        </div>
      </MainLayout>
    );
  return (
    <MainLayout>
      <section className="container mx-auto px-5 py-10">
        <div className="mx-auto w-full max-w-sm">
          <h1 className="font-roboto mb-8 text-center text-2xl font-bold text-dark-hard">
            Reset Password
          </h1>
          <form onSubmit={handleSubmit(submitHandler)}>
            <div className="mb-6 flex w-full flex-col">
              <label
                htmlFor="password"
                className="font-semibold text-dark-hard"
              >
                New Password
              </label>
              <input
                type="password"
                id="password"
                {...register("password", {
                  required: {
                    value: true,
                    message: "Password is required",
                  },
                  minLength: {
                    value: 6,
                    message: "Password length must be atleast 6 characters",
                  },
                })}
                placeholder="Enter password"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.password ? "border-red-500" : ""
                }`}
              />
              {errors.password?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.password.message}
                </p>
              )}
            </div>
            <div className="mb-6 flex w-full flex-col">
              <label
                htmlFor="confirmPassword"
                className="font-semibold text-dark-hard"
              >
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                {...register("confirmPassword", {
                  required: {
                    value: true,
                    message: "Confirm Password is required",
                  },
                  validate: (value) => {
                    if (value !== password) return "Passwords do not match";
                  },
                })}
                placeholder="Enter password again"
                className={`mt-3 block rounded-lg border px-5 py-4 font-semibold text-dark-hard outline-none placeholder:text-gray-200 ${
                  errors.confirmPassword ? "border-red-500" : ""
                }`}
              />
              {errors.confirmPassword?.message && (
                <p className="mt-1 text-xs text-red-500">
                  {errors?.confirmPassword.message}
                </p>
              )}
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="my-6 w-full rounded-lg bg-primary px-8 py-4 text-lg font-bold text-white disabled:cursor-not-allowed disabled:opacity-30"
            >
              Submit
            </button>
          </form>
        </div>
      </section>
    </MainLayout>
  );
}
