import logo from "../assets/logo.jpg";
import blog from "../assets/intropic.jpg";
import laptop from "../assets/laptop.png";
import profile_pic from "../assets/default_profile_pic.jpg";

const images = {
  logo,
  blog,
  laptop,
  profile_pic,
};

export default images;
