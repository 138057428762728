import React from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";

export const NavItemCollapse = ({
  children,
  title,
  name,
  activeNavName,
  setActiveNavName,
}) => {
  return (
    <div className="z-50">
      <section>
        <label>
          <input
            className="peer/showLabel absolute scale-0"
            type="checkbox"
            checked={activeNavName === name}
            onChange={() => {
              setActiveNavName(name);
            }}
          />
          <span
            className={` block max-h-14 overflow-hidden text-lg transition-all duration-300 peer-checked/showLabel:max-h-52`}
          >
            <div
              className={`${name === activeNavName ? "font-bold text-primary" : "font-semibold text-gray-400"} flex flex-row items-center gap-x-3 `}
            >
              {name === activeNavName ? (
                <AiOutlineCaretUp />
              ) : (
                <AiOutlineCaretDown />
              )}
              <h3
                className={`${name === activeNavName ? "font-bold text-primary" : "font-semibold text-gray-400"} flex h-14 cursor-pointer items-center font-bold`}
              >
                {title}
              </h3>
            </div>

            <div className="flex flex-col gap-y-2 px-9 text-gray-400 duration-300">
              {children}
            </div>
          </span>
        </label>
      </section>
    </div>
  );
};
