import React from "react";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "../../../utils/dateFormatter";
import { images } from "../../../constants";

export default function SuggestedPosts({
  className,
  title,
  posts = [],
  tags = [],
}) {
  const { slug } = useParams();

  return (
    <div
      className={`shadow-[inset_-12px_-8px_40px_#46464620 w-full rounded-lg p-4 ${className}`}
    >
      <h2 className="font-md text-dark-hard md:text-xl">{title}</h2>
      <div className="md:grif-cols-2 mt-5 grid gap-y-5 md:gap-x-5 lg:grid-cols-1">
        {[
          posts.map((item, index) => {
            if (item.slug !== slug) {
              return (
                <Link to={`/post/${item.slug}`} key={item.slug}>
                  <div
                    key={`posts-${index}`}
                    className="flex flex-nowrap items-center space-x-3"
                  >
                    <img
                      src={item.imageURL || images.logo}
                      className="aspect-square w-1/5 rounded-lg object-cover"
                    />
                    <div className="text=sm font-medium text-dark-light lg:text-lg">
                      <h3>{item.title}</h3>
                      <span className="text-sm opacity-50">
                        {formatDate(item.createdAt)}
                      </span>
                    </div>
                  </div>
                </Link>
              );
            }
          }),
        ]}
      </div>
      {tags.length > 0 && (
        <>
          <h2 className="font-md mt-8 text-dark-hard md:text-xl">Tags:</h2>
          <div className="mt-4 flex flex-wrap gap-x-2 gap-y-2">
            {tags.map((item, index) => {
              return (
                <div key={`tags-${index}`}>
                  <Link
                    to="/"
                    className="inline-block rounded-md bg-primary bg-opacity-10 px-3 py-1.5 text-xs text-primary md:text-sm"
                  >
                    {item}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
