import React from "react";
import images from "../constants/images";
import { AiFillCheckCircle, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

// sadows from here: https://manuarora.in/boxshadows

export default function BlogCard({ post, className }) {
  return (
    <div
      className={`rounded-xl overflow-hidden shadow-[inset_-12px_-8px_40px_#46464620] ${className}`}
    >
      <Link to={`/post/${post.slug}`}>
        <img
          src={post.imageURL ? post.imageURL : null}
          className="w-full object-cover object-center h-auto xs:h-10  md:h-52 lg:h-48 xl:h-60 lg:text-[28px]"
        />
        <div className="p-5">
          <h2 className="font-bold text-xl text-dark-soft md:text-2xl">
            {post.title}
          </h2>
          <p className="text-dark-light mt-3 text-sm md:text-lg">
            {post.caption}
          </p>
          <div className="flex justify-between flex-nowrap items-center mt-6">
            <div className="flex items-center  gap-x-2 md:gap-x-2.5">
              <img
                src={
                  post.user.imageURL ? post.user.imageURL : images.profile_pic
                }
                className="w-9 h-9 md:w-10 md:h-10 rounded-full"
                alt="profile picture"
              />
              <div className="flex flex-col">
                <h4 className="font-bold italic text-dark-hard text-sm">
                  {post.user.name}
                </h4>
                <div className="flex items-center gap-x-2">
                  <span
                    className={`w-fit bg-opacity-20 p1.5 rounded-full ${
                      post.user.verified ? "bg-green-400" : "bg-red-400"
                    }`}
                  >
                    {post.user.verified ? (
                      <AiFillCheckCircle />
                    ) : (
                      <AiOutlineClose className=" text-red-500" />
                    )}
                  </span>
                  <span className="italic text-dark-light text-xs">
                    {post.user.verified
                      ? "Verified Writer"
                      : "Unverified Writer"}
                  </span>
                </div>
              </div>
            </div>
            <div className="font-bold text-dark-light text-sm">
              {new Date(post.createdAt).getDate()}{" "}
              {new Date(post.createdAt).toLocaleDateString("default", {
                month: "long",
              })}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
