import React from "react";
import { deletePost, getAllPosts } from "../../../../services/index/posts";
import { Link } from "react-router-dom";
import { useDataTable } from "../../../../hooks/useDataTable";
import { DataTable } from "../../components/DataTable";
import { formatDate } from "../../../../utils/dateFormatter";

export const AdminManagePosts = () => {
  const {
    userState,
    currentPage,
    searchKeyword,
    data: postsData,
    isLoading,
    isFetching,
    isLoadingDeleteData,
    searchKeywordHandler,
    submitSearchKeyWordHandler,
    deleteDataHandler,
    setCurrentPage,
  } = useDataTable({
    dataQueryFunction: () => getAllPosts(searchKeyword, currentPage),
    dataQueryKey: "posts",
    deleteDataMessage: "Post has been delete",
    mutateDeleteFunction: ({ slug, token }) => {
      return deletePost({ slug, token });
    },
  });

  return (
    <DataTable
      pageTitle="Manage Posts"
      dataListName="Posts"
      searchInputPlaceHolder="Post Title..."
      searchKeyWordOnSubmitHandler={submitSearchKeyWordHandler}
      searchKeyWordOnChangeHandler={searchKeywordHandler}
      searchKeyword={searchKeyword}
      tableHeaderTitleList={["Title", "Category", "Created At", "Tags", ""]}
      isLoading={isLoading}
      isFetching={isFetching}
      data={postsData}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      headers={postsData?.headers}
    >
      {postsData?.data?.map((post) => {
        return (
          <tr key={post._id}>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to={`/post/${post.slug}`} className="relative block">
                    <img
                      alt="postImage"
                      src={post.imageURL || null}
                      className="mx-auto h-10 w-10 rounded-lg object-cover "
                    />
                  </Link>
                </div>
                <div className="ml-3">
                  <p className="whitespace-no-wrap text-gray-900">
                    {post.title}
                  </p>
                </div>
              </div>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap text-gray-900">
                {post?.categories?.length > 0
                  ? post?.categories.slice(0, 3).map((cat) => cat.title + " ")
                  : "Uncategoried"}
              </p>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <p className="whitespace-no-wrap text-gray-900">
                {formatDate(post.createdAt)}
              </p>
            </td>
            <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <div className="flex gap-x-2">
                {post?.tags.length > 0 ? (
                  post.tags.map((tag, index) => (
                    <p
                      key={`${tag}-${index}`}
                      className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 font-semibold text-primary"
                    >
                      {tag}
                    </p>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </td>
            <td className="space-x-5 border-b border-gray-200 bg-white px-5 py-5 text-sm">
              <Link
                to={`/admin/posts/manage/edit/${post?.slug}`}
                className="text-indigo-600 hover:text-indigo-900"
              >
                Edit
              </Link>
              <button
                disabled={isLoadingDeleteData}
                type="button"
                className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed disabled:opacity-70"
                onClick={() =>
                  deleteDataHandler({
                    slug: post?.slug,
                    token: userState.userInfo.token,
                  })
                }
              >
                Delete
              </button>
            </td>
          </tr>
        );
      })}
    </DataTable>
  );
};
