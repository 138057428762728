import React, { useState } from "react";
import CommentForm from "./CommentForm";
import Comment from "./Comment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createnewComment,
  editComment,
  removeComment,
  addOrRemoveLikeOnComment,
} from "../../services/index/comments";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
export default function CommentsContainer({
  className,
  userLoggedInId,
  comments,
  postSlug,
}) {
  const queryClient = useQueryClient();
  const [alterComment, setAlterComment] = useState(null);
  const userState = useSelector((state) => state.user);

  const { mutate: mutateNewComment, isLoading: isLoadingNewComment } =
    useMutation({
      mutationFn: ({ token, desc, slug, parent, replyOnUser }) => {
        return createnewComment({ token, desc, slug, parent, replyOnUser });
      },
      onSuccess: () => {
        toast.success("Posted Comment");
        queryClient.invalidateQueries(["post"]);
      },
      onError: () => {
        toast.error("There was an issue posting your comment");
      },
    });

  const addComment = (value, parent = null, replyOnUser = null) => {
    mutateNewComment({
      desc: value,
      parent,
      replyOnUser,
      token: userState.userInfo.token,
      slug: postSlug,
    });
    setAlterComment(null);
  };

  const { mutate: mutateUpdateComment } = useMutation({
    mutationFn: ({ token, desc, commentId }) => {
      return editComment({ token, desc, commentId });
    },
    onSuccess: () => {
      toast.success("Edited comment");
      queryClient.invalidateQueries(["post"]);
    },
    onError: () => {
      toast.error("There was an issue editing your comment");
    },
  });

  const updateComment = (value, commentId) => {
    mutateUpdateComment({
      token: userState.userInfo.token,
      desc: value,
      commentId,
    });
    setAlterComment(null);
  };

  const { mutate: mutateDeleteComment } = useMutation({
    mutationFn: ({ token, commentId }) => {
      return removeComment({ token, commentId });
    },
    onSuccess: () => {
      toast.success("Deleted comment");
      queryClient.invalidateQueries(["post"]);
    },
    onError: () => {
      toast.error("There was an issue editing your comment");
    },
  });

  const deleteComment = (commentId) => {
    mutateDeleteComment({ token: userState.userInfo.token, commentId });
  };

  const { mutate: mutateLikeComment } = useMutation({
    mutationFn: ({ token, commentId }) => {
      return addOrRemoveLikeOnComment({ token, commentId });
    },
    onSuccess: () => {
      toast.success("Liked/Unliked comment");
      queryClient.invalidateQueries(["post"]);
    },
    onError: () => {
      toast.error("There was an issue liking your comment");
    },
  });

  const likeComment = (commentId) => {
    mutateLikeComment({ token: userState.userInfo.token, commentId });
    console.log("liked: ", commentId);
  };

  return (
    <div className={`${className}`}>
      <CommentForm
        label={"Submit"}
        formSubmit={(value) => addComment(value)}
        loading={isLoadingNewComment}
      />
      <div className="space-y-4 mt-8 ">
        {comments.map((comment, index) => {
          return (
            <Comment
              key={`comment-${index}`}
              comment={comment}
              userLoggedInId={userLoggedInId}
              alterComment={alterComment}
              setAlterComment={setAlterComment}
              addComment={addComment}
              updateComment={updateComment}
              deleteComment={deleteComment}
              likeComment={likeComment}
              replies={comment.replies}
            />
          );
        })}
      </div>
    </div>
  );
}
