import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getSinglePost, updatePost } from "../../../../services/index/posts";
import CreatableSelect from "react-select/creatable";
import { Link, useParams } from "react-router-dom";
import { BlogSkeleton } from "../../../blogPost/components/BlogSkeleton";
import toast from "react-hot-toast";
import { AiOutlineCamera } from "react-icons/ai";
import { urlToObj } from "../../../../utils/urlToObj";
import { useSelector } from "react-redux";
import { Editor } from "../../../../components/editor/Editor";
import { MultiSelectTagDropdown } from "../../components/selectDropdown/MultiSelectTagDropdown";
import { getAllCategories } from "../../../../services/index/postCategories";
import {
  categoryToOption,
  filterCategories,
} from "../../../../utils/multiSelectTagUtil";

export const AdminEditPost = () => {
  const { slug } = useParams();
  const queryClient = useQueryClient();
  const [photo, setPhoto] = useState(null);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const userState = useSelector((state) => state.user);
  const [body, setBody] = useState(null);
  const [categories, setCategories] = useState(null);
  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  const [tags, setTags] = useState(null);

  const { data, isLoading, isError, error } = useQuery({
    queryFn: () => getSinglePost({ slug }),
    queryKey: ["post", slug],
  });

  const { data: postCategories, isLoading: isCategoryLoading } = useQuery({
    queryFn: () =>
      getAllCategories({ searchKeyword: "", pageLimit: 100, currentPage: 1 }),
    queryKey: ["categories", slug],
  });

  const { mutate, isLoading: isLoadingMutation } = useMutation({
    mutationFn: ({ updatedData, slug, token }) => {
      return updatePost({
        updatedData,
        slug,
        token,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["blog", slug]);
      toast.success("Updated Post");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  };

  const handleUpdatePost = async () => {
    let updatedData = new FormData();
    if (photo) {
      const newPicture = await urlToObj(URL.createObjectURL(photo), photo);
      updatedData.append("photo", newPicture);
    }

    updatedData.append(
      "document",
      JSON.stringify({ body, categories, title, caption, tags }),
    );
    mutate({ updatedData, slug, token: userState.userInfo.token });
  };

  useEffect(() => {
    if (isError) {
      toast.error(error.message);
    }
    if (!isError && !isLoading) {
      setInitialPhoto(data?.photo);
    }
    setTitle(data?.title);
    setCaption(data?.caption);
    setCategories(data?.categories?.map((item) => item?._id));
    setTags(data?.tags);
  }, [data, isError, isLoading]);

  const promiseOptions = async (inputValue) => {
    return filterCategories(inputValue, postCategories?.data);
  };

  return (
    <div>
      {isLoading ? (
        <BlogSkeleton />
      ) : (
        <section className="max-w-5-xl flex flex-col bg-gray-100 px-2 lg:flex-row lg:items-start lg:gap-x-5">
          <article className="flex-1">
            <label htmlFor="postPicture" className="w-full cursor-pointer">
              <div className="flex max-h-96 justify-center">
                {photo ? (
                  <img
                    src={URL.createObjectURL(photo)}
                    alt={data?.title}
                    className="w-full rounded-xl object-contain"
                  />
                ) : initialPhoto ? (
                  <img
                    src={data?.imageURL}
                    alt={data?.title}
                    className="w-full rounded-xl object-contain"
                  />
                ) : (
                  <div className="flex h-full min-h-[200px] w-full items-center justify-center bg-slate-200">
                    <AiOutlineCamera className="h-auto w-7 text-gray-400" />
                  </div>
                )}
              </div>
            </label>
            <input
              type="file"
              className="sr-only"
              id="postPicture"
              onChange={(e) => handleFileChange(e)}
            />
            <div className="mt-4 flex gap-2">
              {data?.categories.map((category) => {
                return (
                  <Link
                    to={`posts?category=${category?.name}`}
                    className="text-md font-md text-dark-hard"
                  >
                    {category?.name}
                  </Link>
                );
              })}
            </div>
            <div className="w-full">
              <input
                className=" mt-4 w-full rounded-lg border-slate-300 p-2 text-3xl text-primary outline-slate-300"
                placeholder="sample title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <h1 className="md:3xl px-1 text-sm text-dark-light">Title</h1>
            </div>
            <div className="w-full">
              <input
                className=" mt-4 w-full rounded-lg border-slate-300 p-2 text-3xl text-primary outline-slate-300 "
                placeholder="sample caption"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
              <h1 className="md:3xl px-1 text-sm text-dark-light">Caption</h1>
            </div>
            <div className="my-5">
              {!isLoading && !isCategoryLoading && (
                <MultiSelectTagDropdown
                  loadOptions={promiseOptions}
                  onChange={(newValue) =>
                    setCategories(newValue.map((item) => item?.value))
                  }
                  defaultValue={data?.categories?.map(categoryToOption)}
                />
              )}
              <h1 className="md:3xl px-1 text-sm text-dark-light">Category</h1>
            </div>
            <div className="my-5">
              {
                <CreatableSelect
                  className="relative z-20 "
                  defaultValue={data?.tags?.map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                  isMulti
                  onChange={(newValue) =>
                    setTags(newValue.map((item) => item.value))
                  }
                />
              }
              <h1 className="md:3xl px-1 text-sm text-dark-light">Tags</h1>
            </div>
            <div className="w-full">
              {!isLoading && !isError && (
                <Editor
                  content={data?.body}
                  editable={true}
                  onDataChange={(data) => {
                    setBody(data);
                  }}
                />
              )}
            </div>
            <button
              disabled={isLoadingMutation}
              type="button"
              onClick={() => handleUpdatePost()}
              className="my-6 w-full rounded-lg bg-green-500 px-4 py-2 font-semibold text-white disabled:cursor-not-allowed disabled:opacity-70"
            >
              Update Post
            </button>
          </article>
        </section>
      )}
    </div>
  );
};
