import React, { useState } from "react";
import { images } from "../constants/index";
import { AiOutlineMenu, AiOutlineClose, AiFillCaretDown } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../store/actions/user";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState(false);
  const [profileDropDown, setProfileDropDown] = useState(false);
  const userState = useSelector((state) => state.user);
  const queryClient = useQueryClient();

  const toggleDropDown = () => {
    setDropDown((dropDown) => {
      return !dropDown;
    });
  };

  const toggleProfileDropDown = () => {
    setProfileDropDown((profileDropDown) => {
      return !profileDropDown;
    });
  };

  const logoutHandler = () => {
    queryClient.removeQueries();
    dispatch(logout());
  };

  const navItemArray = [
    { name: "Home", type: "link", href: "/" },
    { name: "Posts", type: "link", href: "/post" },
    {
      name: "Pages",
      type: "dropdown",
      items: [
        { title: "About Us", href: "/about" },
        { title: "Contact Us", href: "/contact" },
      ],
    },
    { name: "Pricing", type: "link", href: "/pricing" },
    { name: "FAQ", type: "link", href: "/faq" },
  ];

  const [visibleNav, setVisibleNav] = useState(false);
  const navVisibilityHandler = () => {
    setVisibleNav((currentState) => !currentState);
  };

  const NavItem = ({ item }) => {
    return (
      <li className="group relative">
        {item.type === "link" ? (
          <>
            <Link to={item.href} className="px-4 py-2">
              {item.name}
            </Link>
            <span className="absolute right-0 top-0 cursor-pointer font-bold text-orange-500 opacity-0 transition-all duration-500 group-hover:right-[90%] group-hover:opacity-100">
              /
            </span>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <button
              className="flex items-center gap-x-3 px-4 py-2"
              onClick={toggleDropDown}
            >
              {item.name}
              <AiFillCaretDown />
            </button>
            <div
              className={`${
                dropDown ? "block" : "hidden"
              } transition:all w-max rounded-lg bg-white pt-4 duration-500 group-hover:block lg:absolute lg:bottom-0 lg:right-0 lg:hidden lg:translate-y-full lg:transform`}
            >
              <ul className="sadow-lg flex flex-col overflow-hidden rounded-lg bg-dark-hard text-center lg:bg-transparent">
                {item.items.map((page, index) => {
                  return (
                    <Link
                      key={`navItem-${index}`}
                      to={page.href}
                      className="px-4 py-2 text-white hover:bg-dark-hard hover:text-white lg:text-dark-light"
                    >
                      {page.title}
                    </Link>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </li>
    );
  };

  return (
    <section className="sticky left-0 right-0 top-0 z-50 bg-white">
      <header className="container mx-auto flex items-center justify-between px-5 py-4">
        <Link to="/">
          <img
            className="h-auto w-28 object-scale-down"
            src={images.logo}
            alt="logo"
          />
        </Link>
        {userState.userInfo && !userState?.userInfo?.verified && (
          <Link to="/profile">
            <p className="mt-5 flex h-14 w-auto flex-row items-center rounded-full border-2 border-dark-light px-6 py-2 text-xs font-semibold text-dark-light transition-all duration-300 hover:border-primary hover:bg-primary hover:text-white lg:mt-0 lg:text-lg xl:text-lg 2xl:text-lg">
              Please verify your email. You will not be able to comment or post
              until you do
            </p>
          </Link>
        )}
        <div className="lg:hidden">
          {visibleNav ? (
            <AiOutlineClose
              className="h-6 w-6 "
              onClick={navVisibilityHandler}
            />
          ) : (
            <AiOutlineMenu
              className="h-6 w-6 "
              onClick={navVisibilityHandler}
            />
          )}
        </div>
        <div
          className={` ${
            visibleNav ? "right-0" : "-right-full"
          } fixed bottom-0 top-0 z-[49] mt-28 flex w-full flex-col items-center justify-center gap-x-9 bg-dark-soft transition-all duration-300 lg:static lg:mt-0 lg:w-auto lg:flex-row lg:justify-end lg:bg-transparent`}
        >
          <ul className="flex flex-col items-center gap-x-5 gap-y-5 font-semibold text-white lg:flex-row lg:text-dark-light">
            {navItemArray.map((item) => {
              return <NavItem key={`nav-${item.name}`} item={item} />;
            })}
          </ul>
          {userState.userInfo ? (
            <div className="flex flex-col items-center gap-x-5 gap-y-5 font-semibold text-white lg:flex-row lg:text-dark-light">
              <div className="group relative">
                <div className="flex flex-col items-center">
                  <button
                    className="mt-5 flex h-14 w-auto flex-row items-center rounded-full border-2 px-6  py-2 font-semibold text-white transition-all duration-300 hover:border-primary hover:bg-primary hover:text-white lg:mt-0 lg:border-dark-light lg:text-dark-light"
                    onClick={toggleProfileDropDown}
                  >
                    Profile <AiFillCaretDown />
                  </button>
                  <div
                    className={`${
                      profileDropDown ? "block" : "hidden"
                    } transition:all w-max rounded-lg bg-white pt-4 duration-500 group-hover:block lg:absolute lg:bottom-0 lg:right-0 lg:hidden lg:translate-y-full lg:transform`}
                  >
                    <ul className="sadow-lg flex flex-col overflow-hidden rounded-lg bg-dark-hard text-center lg:bg-transparent">
                      {userState?.userInfo?.admin && (
                        <button
                          onClick={() => navigate("/admin")}
                          type="button"
                          className="px-4 py-2 text-white hover:bg-dark-hard hover:text-white lg:text-dark-light"
                        >
                          Admin Dashboard
                        </button>
                      )}
                      <button
                        onClick={() => navigate("/profile")}
                        type="button"
                        className="px-4 py-2 text-white hover:bg-dark-hard hover:text-white lg:text-dark-light"
                      >
                        Account
                      </button>
                      <button
                        onClick={logoutHandler}
                        type="button"
                        className="px-4 py-2 text-white hover:bg-dark-hard hover:text-white lg:text-dark-light"
                      >
                        Logout
                      </button>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <button
              onClick={() => navigate("/login")}
              className="mt-5 h-14 w-auto rounded-full border-2  px-6  py-2 font-semibold text-white transition-all duration-300 hover:border-primary hover:bg-primary hover:text-white lg:mt-0 lg:border-dark-light lg:text-dark-light"
            >
              Sign In
            </button>
          )}
        </div>
      </header>
    </section>
  );
}
