import React from "react";
import images from "../../../constants/images";
import { Search } from "../../../components/Search";
import { useNavigate } from "react-router-dom";

export default function MainPage() {
  const navigate = useNavigate();

  const handleSearch = ({ searchKeyword }) => {
    navigate(`/post?page=1&search=${searchKeyword}`);
  };

  return (
    <section className="container mx-auto flex flex-col px-5 py-5 lg:flex-row">
      <div className="mt-10 lg:w-1/2">
        <h1 className="text-center text-3xl font-bold text-dark-soft md:text-5xl lg:max-w-[540px] lg:text-left lg:text-4xl xl:text-5xl">
          Welcome to doomer history
        </h1>
        <p className="mt-4 text-center text-dark-light md:text-xl lg:text-left lg:text-base xl:text-xl">
          Read, comment, and post your own historical opinions!
        </p>
        <Search
          className={" mt-10 flex flex-col gap-y-2.5"}
          onSearchKeyword={handleSearch}
        />
        <div className="mt-4 flex flex-col lg:mt-7 lg:flex-row lg:flex-nowrap lg:items-start lg:gap-x-4">
          <span className="mt-2 font-semibold italic text-dark-light lg:mt-4 ">
            Popular Tags:{" "}
          </span>
          <ul className="mt-3 flex flex-wrap gap-x-2.5 gap-y-2.5">
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 font-semibold text-primary">
              Desgins
            </li>
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 font-semibold text-primary">
              User Experience
            </li>
            <li className="rounded-lg bg-primary bg-opacity-10 px-3 py-1.5 font-semibold text-primary">
              User Interface
            </li>
          </ul>
        </div>
      </div>
      <div className="lg:1/2 hidden px-5 lg:block">
        <img className="w-full" alt="blog" src={images.blog} />
      </div>
    </section>
  );
}
