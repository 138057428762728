import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { images } from "../../../../constants";
import {
  AiFillDashboard,
  AiOutlineClose,
  AiOutlineMenu,
  AiOutlineMessage,
  AiOutlineUser,
} from "react-icons/ai";
import { NavItem } from "./NavItem";
import { NavItemCollapse } from "./NavItemCollapse";
import useWindowDimensions from "../../../../hooks/getWindowDimensions";

export const AdminHeader = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [activeNavName, setActiveNavName] = useState("dashboard");
  const toggleMenuHandler = () => {
    setIsMenuActive((prevState) => !prevState);
  };
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 1024) {
      setIsMenuActive(false);
    } else {
      setIsMenuActive(true);
    }
  }, [width]);

  return (
    <header className="lg:max-[300px] flex h-fit w-full items-center justify-between p-4 lg:max-w-xs lg:flex-col lg:items-start lg:justify-start lg:p-0">
      {/* logo */}
      <Link to="/" className="h-auto w-28 object-scale-down lg:hidden ">
        <img src={images.logo} alt="logo" className="w-16" />
      </Link>
      {/* menu burger */}
      <div className="cursor-pointer lg:hidden">
        {isMenuActive ? (
          <AiOutlineClose className="h-6 w-6" onClick={toggleMenuHandler} />
        ) : (
          <AiOutlineMenu className="h-6 w-6" onClick={toggleMenuHandler} />
        )}
      </div>
      {/* sidebar container */}
      {isMenuActive && (
        <div className="fixed inset-0 bg-white lg:static  lg:h-full lg:w-full">
          {/* underlay */}

          <div className="fixed inset-0 bg-black opacity-50 lg:hidden" />
          {/* sidebar */}
          <div className="fixed bottom-0 left-0 top-0 z-50 w-3/4 overflow-y-auto bg-white p-4 lg:static lg:h-full lg:w-full ">
            <div className="flex w-auto cursor-pointer flex-row-reverse lg:hidden">
              <AiOutlineClose className="h-6 w-6" onClick={toggleMenuHandler} />
            </div>
            <div className="w-28">
              <Link to="/">
                <img
                  src={images.logo}
                  alt="logo"
                  className="h-auto w-28 object-scale-down"
                />
              </Link>
            </div>
            <h4 className="mt-10 text-lg font-bold text-dark-hard">
              Admin Menu
            </h4>
            {/* menu items */}
            <div className="mt-6 flex flex-col gap-y-[0.563rem]">
              <NavItem
                title={"Dashboard"}
                link={"/admin"}
                icon={<AiFillDashboard className="text-xl" />}
                name={"dashboard"}
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />
              <NavItem
                title={"Comments"}
                link={"/admin/comments"}
                icon={<AiOutlineMessage className="text-xl" />}
                name={"comments"}
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />
              <NavItem
                title={"Users"}
                link={"/admin/users/manage"}
                icon={<AiOutlineUser className="text-xl" />}
                name={"users"}
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />
              <NavItemCollapse
                title={"Posts"}
                name={"posts"}
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              >
                <Link to="/admin/posts/new">Create New Post</Link>
                <Link to="/admin/posts/manage">Manage Posts</Link>
                <Link to="/admin/categories/manage">Categories</Link>
              </NavItemCollapse>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};
