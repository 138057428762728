import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getSingleCategory,
  updateCategory,
} from "../../../../services/index/postCategories";

export const AdminEditCategories = () => {
  const [newCategoryTitle, setNewCategoryTitle] = useState();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { slug } = useParams();
  const userState = useSelector((state) => state.user);

  const {
    data: categoryData,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
  } = useQuery({
    queryFn: () => getSingleCategory({ slug }),
    queryKey: ["categories", slug],
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const { mutate: mutateUpdateCategory, isLoading: isLoadingUpdateCategory } =
    useMutation({
      mutationFn: ({ title, slug, token }) => {
        return updateCategory({
          title,
          slug,
          token,
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["categories", slug]);
        toast.success("Updated Category");
        navigate(`/admin/categories/manage`);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const handleUpdateCategory = async () => {
    if (!newCategoryTitle) {
      return;
    }

    mutateUpdateCategory({
      title: newCategoryTitle,
      slug,
      token: userState.userInfo.token,
    });
  };

  useEffect(() => {
    setNewCategoryTitle(categoryData?.title);
  }, [categoryData]);

  return (
    <div className="col-span-4 py-8">
      <h4 className="text-2xl leading-tight">Update Category</h4>
      <div className="w-full">
        <input
          className=" mt-4 w-full rounded-lg border-slate-300 p-2 text-lg text-gray-500 outline-slate-300 "
          placeholder="sample category title"
          value={newCategoryTitle}
          onChange={(e) => setNewCategoryTitle(e.target.value)}
        />
        <button
          disabled={
            isLoadingUpdateCategory || isCategoryLoading || isCategoryError
          }
          type="button"
          onClick={() => handleUpdateCategory()}
          className="my-6 w-fit rounded-lg bg-green-500 px-4 py-2 font-semibold text-white disabled:cursor-not-allowed disabled:opacity-70"
        >
          Update Category
        </button>
      </div>
    </div>
  );
};
