import React, { useState } from "react";
import { DataTable } from "../../components/DataTable";
import { useDataTable } from "../../../../hooks/useDataTable";
import {
  createCategory,
  deleteCategory,
  getAllCategories,
} from "../../../../services/index/postCategories";
import { formatDate } from "../../../../utils/dateFormatter";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";

export const AdminCategories = () => {
  const [newCategoryTitle, setNewCategoryTitle] = useState("");
  const queryClient = useQueryClient();

  const {
    userState,
    currentPage,
    searchKeyword,
    data: categoriesData,
    isLoading,
    isFetching,
    isLoadingDeleteData,
    searchKeywordHandler,
    submitSearchKeyWordHandler,
    deleteDataHandler,
    setCurrentPage,
  } = useDataTable({
    dataQueryFunction: () =>
      getAllCategories({ searchKeyword, pageLimit: 10, currentPage }),
    dataQueryKey: "categories",
    deleteDataMessage: "Category has been deleted",
    mutateDeleteFunction: ({ slug, token }) => {
      return deleteCategory({ slug, token });
    },
  });

  const { mutate: mutateCreateCategory, isLoading: isLoadingCreateCategory } =
    useMutation({
      mutationFn: ({ token, title }) => {
        return createCategory({
          token,
          title,
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["categories"]);
        toast.success("Category is created");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const handleCreateCategory = () => {
    mutateCreateCategory({
      token: userState.userInfo.token,
      title: newCategoryTitle,
    });
  };

  return (
    <div className="grid grid-cols-12 gap-x-4">
      <div className="col-span-4 py-8">
        <h4 className="text-2xl leading-tight">Add New Category</h4>
        <div className="w-full">
          <input
            className=" mt-4 w-full rounded-lg border-slate-300 p-2 text-lg text-gray-500 outline-slate-300 "
            placeholder="sample category title"
            value={newCategoryTitle}
            onChange={(e) => setNewCategoryTitle(e.target.value)}
          />
          <button
            disabled={isLoadingCreateCategory}
            type="button"
            onClick={() => handleCreateCategory()}
            className="my-6 w-fit rounded-lg bg-green-500 px-4 py-2 font-semibold text-white disabled:cursor-not-allowed disabled:opacity-70"
          >
            Submit New Category
          </button>
        </div>
      </div>
      <div className="col-span-8 ">
        <DataTable
          pageTitle={""}
          dataListName={"Categories"}
          searchInputPlaceHolder={"Search Category"}
          searchKeyWordOnSubmitHandler={submitSearchKeyWordHandler}
          searchKeyWordOnChangeHandler={searchKeywordHandler}
          searchKeyword={searchKeyword}
          tableHeaderTitleList={["TItle", "Created At", ""]}
          isFetching={isFetching}
          isLoading={isLoading}
          data={categoriesData?.data}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          headers={categoriesData?.headers}
        >
          {categoriesData?.data?.map((category) => {
            return (
              <tr key={category._id}>
                <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <div className="flex items-center">
                    <p className="whitespace-no-wrap text-gray-900">
                      {category.title}
                    </p>
                  </div>
                </td>
                <td className="border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <p>{formatDate(category.createdAt)}</p>
                </td>
                <td className="space-x-5 border-b border-gray-200 bg-white px-5 py-5 text-sm">
                  <Link
                    to={`/admin/categories/manage/edit/${category?._id}`}
                    className="text-blue-600 hover:text-blue-900"
                  >
                    Edit
                  </Link>
                  <button
                    disabled={isLoadingDeleteData}
                    type="button"
                    className="text-red-600 hover:text-red-900 disabled:cursor-not-allowed disabled:opacity-70"
                    onClick={() =>
                      deleteDataHandler({
                        slug: category?._id,
                        token: userState.userInfo.token,
                      })
                    }
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </DataTable>
      </div>
    </div>
  );
};
