import React from "react";
import { NavLink } from "react-router-dom";

export const NavItem = ({
  link = "",
  title,
  icon,
  name,
  activeNavName,
  setActiveNavName,
}) => {
  return (
    <NavLink
      to={link}
      className={`${name === activeNavName ? "font-bold text-primary" : "font-semibold text-gray-400"} flex items-center gap-x-2 py-2 text-lg`}
      onClick={() => setActiveNavName(name)}
    >
      {icon} {title}
    </NavLink>
  );
};
