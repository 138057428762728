import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";

export const signup = async ({
  name,
  email,
  password,
  hCaptchaToken,
  hCaptchaEkey,
}) => {
  try {
    const { data } = await axios.post(`${apiUrl()}/api/users/register`, {
      name,
      email,
      password,
      hCaptchaToken,
      hCaptchaEkey,
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const login = async ({
  email,
  password,
  hCaptchaToken,
  hCaptchaEkey,
}) => {
  try {
    const { data } = await axios.post(`${apiUrl()}/api/users/login`, {
      email,
      password,
      hCaptchaToken,
      hCaptchaEkey,
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const getUserProfile = async ({ token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(`${apiUrl()}/api/users/profile`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const updateProfile = async ({ token, userData, userId }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log("hits updateProfile");
    const { data } = await axios.put(
      `${apiUrl()}/api/users/updateProfile/${userId}`,
      userData,
      config,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const updateProfilePicture = async ({ token, formData }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(
      `${apiUrl()}/api/users/updateProfilePicture`,
      formData,
      config,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const getUserProfilePicture = async ({ token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${apiUrl()}/api/users/getProfilePicture`,
      config,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const deleteProfilePicutre = async ({ token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.delete(
      `${apiUrl()}/api/users/deleteProfilePicture`,
      config,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const getAllUsers = async (
  token,
  searchKeyword = "",
  page = 1,
  limit = 10,
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data, headers } = await axios.get(
      `${apiUrl()}/api/users?searchKeyword=${searchKeyword}&page=${page}&limit=${limit}`,
      config,
    );
    return { data, headers };
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const deleteUser = async ({ slug, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.delete(
      `${apiUrl()}/api/users/${slug}`,
      config,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const createNewEmailVerificationCode = async ({ token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(
      `${apiUrl()}/api/users/newEmailToken`,
      {},
      config,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const verifyEmailVerificationCode = async ({ otp, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.put(
      `${apiUrl()}/api/users/verifyEmail`,
      { otp },
      config,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const forgotUserPassword = async ({
  email,
  hCaptchaToken,
  hCaptchaEkey,
}) => {
  try {
    const { data } = await axios.post(`${apiUrl()}/api/users/forgotPassword`, {
      email,
      hCaptchaToken,
      hCaptchaEkey,
    });
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    throw new Error(error.message);
  }
};

export const verifyTokenCode = async ({ token, id }) => {
  try {
    const { data } = await axios.get(
      `${apiUrl()}/api/users/verifyToken?token=${token}&id=${id}`,
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      throw new Error(error.response.data.message);
    }
    return error.message;
  }
};

export const resetUserPassword = async ({
  token,
  id,
  password,
  confirmPassword,
}) => {
  try {
    const { data } = await axios.post(
      `${apiUrl()}/api/users/resetPassword?token=${token}&id=${id}`,
      { password, confirmPassword },
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      console.log(error);
      throw new Error(error.response.data.message);
    }
    return error.message;
  }
};
