import React from "react";
import { AdminHeader } from "./components/header/AdminHeader";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

export const AdminLayout = () => {
  const userState = useSelector((state) => state.user);
  const navigate = useNavigate();

  if (!userState.userInfo) {
    toast.error("Please log in to access admin panel");
    navigate("/");
    return <></>;
  }

  if (!userState.userInfo.admin) {
    toast.error("You are not allowed to access admin panel");
    navigate("/");
    return <></>;
  }

  return (
    <div className="flex h-screen flex-col lg:flex-row">
      <AdminHeader />
      <main className="h-max min-h-screen flex-1 bg-gray-100 lg:p-6">
        {/* outlet component renders whatever is inside the route ex: <Admin /> */}
        <Outlet />
      </main>
    </div>
  );
};
