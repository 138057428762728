import React, { useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import BlogCard from "../../../components/BlogCard";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { getThreeLatestPosts } from "../../../services/index/posts";
import BlogCardSkeleton from "../../../components/BlogCardSkeleton";
import { Error } from "../../../components/Error";
import { Link } from "react-router-dom";

export default function Blogs() {
  const { data, isLoading, isError, error } = useQuery({
    queryFn: () => getThreeLatestPosts(),
    queryKey: ["latest3posts"],
  });

  useEffect(() => {
    if (isError) {
      toast.error(error.message);
    }
  }, [isError]);

  return (
    <section className="container mx-auto flex flex-col px-5 py-10">
      <div className="flex flex-wrap gap-y-5 pb-10 md:gap-x-5">
        {isLoading ? (
          [...Array(3)].map((item, index) => (
            <BlogCardSkeleton
              key={index}
              className={
                "w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
              }
            />
          ))
        ) : !isError ? (
          data?.data.map((post) => {
            return (
              <BlogCard
                key={post._id}
                post={post}
                className={
                  "w-full md:w-[calc(50%-20px)] lg:w-[calc(33.33%-21px)]"
                }
              />
            );
          })
        ) : (
          <Error message={"Could not fetch data"} />
        )}
      </div>
      <Link
        to="/post"
        className="font-bold-text text-light-dark border-light-dark mx-auto flex items-center gap-x-2 rounded-lg border-2 px-5 py-3 transition-all duration-300 hover:border-primary hover:bg-primary hover:text-white"
      >
        <span>More Posts</span>
        <AiOutlineArrowRight className="h-3 w-4" />
      </Link>
    </section>
  );
}
