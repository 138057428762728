import React from "react";
import images from "../../constants/images";
import { formatDate } from "../../utils/dateFormatter";
import {
  AiOutlineMessage,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineLike,
} from "react-icons/ai";
import CommentForm from "./CommentForm";

export default function Comment({
  comment,
  userLoggedInId,
  alterComment,
  setAlterComment,
  addComment,
  parentId = null,
  updateComment,
  deleteComment,
  likeComment,
  replies,
}) {
  const isUserLoggedIn = Boolean(userLoggedInId);
  const usersComment = userLoggedInId === comment.user._id;
  const isReplying =
    alterComment &&
    alterComment.type === "reply" &&
    alterComment._id === comment._id;
  const repliedCommentId = parentId ? parentId : comment._id;
  const replyOnUserId = comment.user._id;

  const isEditing =
    alterComment &&
    alterComment.type === "edit" &&
    alterComment._id === comment._id;

  return (
    <div
      className="flex flex-nowrap items-start gap-x-3 rounded-lg bg-dark-light bg-opacity-10 p-3"
      id={`comment-${comment?._id}`}
    >
      <div className="flex flex-col items-center gap-1">
        <img
          src={comment?.user.imageURL || images.profile_pic}
          alt="profile-pic"
          className="h09 mt-2 h-14 w-14 rounded-lg object-cover"
        />
        <button
          disabled={isUserLoggedIn ? false : true}
          className={`flex items-center space-x-2 text-dark-light`}
          onClick={() => likeComment(comment._id)}
        >
          <AiOutlineLike className="h-auto w-4  hover:fill-primary" />
          <span>{comment?.usersLiked.length || 0}</span>
        </button>
      </div>
      <div className="flex flex-1 flex-col">
        {!isEditing && <p className="text-md mt-2">{comment.desc}</p>}
        {comment.edited && (
          <p className=" text-xs text-dark-light">{"(Edited)"}</p>
        )}

        {isEditing && (
          <CommentForm
            label="Update"
            formCancel={() => setAlterComment(null)}
            initialText={comment.desc}
            formSubmit={(value) => {
              updateComment(value, comment._id);
            }}
          />
        )}
        <h5 className="mt-2 text-xs">{comment.user.name}</h5>

        <span className="text-xs text-dark-light">
          {formatDate(comment.createdAt)}
        </span>

        <div className="mb-3 mt-3 flex items-center gap-x-3 text-sm text-dark-light">
          {isUserLoggedIn && (
            <button
              className="flex items-center space-x-2"
              onClick={() =>
                setAlterComment({ type: "reply", _id: comment._id })
              }
            >
              <AiOutlineMessage className="h-auto w-4 hover:fill-primary" />
            </button>
          )}

          {usersComment && (
            <>
              <button
                className="flex items-center space-x-2"
                onClick={() =>
                  setAlterComment({ type: "edit", _id: comment._id })
                }
              >
                <AiOutlineEdit className="h-auto w-4 hover:fill-primary" />
              </button>
              <button
                onClick={() => deleteComment(comment._id)}
                className="flex items-center space-x-2"
              >
                <AiOutlineDelete className="h-auto w-4 hover:fill-primary" />
              </button>
            </>
          )}
        </div>

        {isReplying && (
          <CommentForm
            label={"Reply"}
            formSubmit={(value) =>
              addComment(value, repliedCommentId, replyOnUserId)
            }
            formCancel={() => setAlterComment(null)}
          />
        )}
        {replies.length > 0 && (
          <div>
            {replies.map((com) => {
              return (
                <Comment
                  key={`reply-${com._id}`}
                  addComment={addComment}
                  alterComment={alterComment}
                  setAlterComment={setAlterComment}
                  comment={com}
                  deleteComment={deleteComment}
                  updateComment={updateComment}
                  userLoggedInId={userLoggedInId}
                  likeComment={likeComment}
                  replies={[]}
                  parentId={comment._id}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
