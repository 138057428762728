import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { createPost } from "../../../../services/index/posts";
import { Editor } from "../../../../components/editor/Editor";
import { AiOutlineCamera } from "react-icons/ai";
import { urlToObj } from "../../../../utils/urlToObj";
import { useNavigate } from "react-router-dom";
import { getAllCategories } from "../../../../services/index/postCategories";
import { filterCategories } from "../../../../utils/multiSelectTagUtil";
import { MultiSelectTagDropdown } from "../../components/selectDropdown/MultiSelectTagDropdown";
import CreatableSelect from "react-select/creatable";

export const AdminNewPost = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const [photo, setPhoto] = useState(null);
  const [body, setBody] = useState(null);
  const [categories, setCategories] = useState(null);
  const [title, setTitle] = useState("");
  const [caption, setCaption] = useState("");
  const [tags, setTags] = useState(null);

  const { mutate: mutateCreatePost, isLoading: isLoadingCreatePost } =
    useMutation({
      mutationFn: ({ newPostData, token }) => {
        return createPost({
          token,
          newPostData,
        });
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(["posts"]);
        toast.success("Post is created");
        navigate("/admin");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const { data: postCategories, isLoading: isCategoryLoading } = useQuery({
    queryFn: () =>
      getAllCategories({ searchKeyword: "", pageLimit: 100, currentPage: 1 }),
    queryKey: ["categories"],
  });

  const handleCreateNewPost = async () => {
    let newPostData = new FormData();
    if (photo) {
      const newPicture = await urlToObj(URL.createObjectURL(photo), photo);
      newPostData.append("photo", newPicture);
    }

    newPostData.append(
      "document",
      JSON.stringify({ body, categories, title, caption, tags }),
    );
    mutateCreatePost({ newPostData, token: userState.userInfo.token });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
  };

  const promiseOptions = async (inputValue) => {
    return filterCategories(inputValue, postCategories?.data);
  };

  return (
    <div>
      <label htmlFor="postPicture" className="w-full cursor-pointer">
        <div className="flex max-h-96 justify-center">
          {photo ? (
            <img
              src={URL.createObjectURL(photo)}
              className="w-full rounded-xl object-contain"
            />
          ) : (
            <div className="flex h-full min-h-[200px] w-full items-center justify-center bg-slate-200">
              <AiOutlineCamera className="h-auto w-7 text-gray-400" />
            </div>
          )}
        </div>
      </label>
      <input
        type="file"
        className="sr-only"
        id="postPicture"
        onChange={(e) => handleFileChange(e)}
      />
      <div className="w-full">
        <input
          className=" mt-4 w-full rounded-lg border-slate-300 p-2 text-3xl text-primary outline-slate-300"
          placeholder="sample title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <h1 className="md:3xl px-1 text-sm text-dark-light">Title</h1>
      </div>
      <div className="w-full">
        <input
          className=" mt-4 w-full rounded-lg border-slate-300 p-2 text-3xl text-primary outline-slate-300 "
          placeholder="sample caption"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
        />
        <h1 className="md:3xl px-1 text-sm text-dark-light">Caption</h1>
      </div>
      <div className="my-5">
        {!isCategoryLoading && (
          <MultiSelectTagDropdown
            loadOptions={promiseOptions}
            onChange={(newValue) =>
              setCategories(newValue.map((item) => item?.value))
            }
          />
        )}
        <h1 className="md:3xl px-1 text-sm text-dark-light">Category</h1>
      </div>
      <div className="my-5">
        {
          <CreatableSelect
            className="relative z-20 "
            isMulti
            onChange={(newValue) => setTags(newValue.map((item) => item.value))}
          />
        }
        <h1 className="md:3xl px-1 text-sm text-dark-light">Tags</h1>
      </div>
      <Editor
        content={null}
        editable={true}
        onDataChange={(data) => {
          setBody(data);
        }}
      />
      <button
        disabled={isLoadingCreatePost}
        className="my-6 w-full rounded-lg bg-green-500 px-4 py-2 font-semibold text-white disabled:cursor-not-allowed disabled:opacity-70"
        onClick={() => handleCreateNewPost()}
      >
        Add New Post
      </button>
    </div>
  );
};
