import React from "react";
import MainLayout from "../../components/MainLayout";
import MainPage from "./container/MainPage";
import Blogs from "./container/Blogs";

export default function Home() {
  return (
    <MainLayout>
      <MainPage />
      <Blogs />
    </MainLayout>
  );
}
