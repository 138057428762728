import { EditorContent, useEditor } from "@tiptap/react";
import { MenuBar } from "./MenuBar";
import { extensions } from "../../constants/tiptapExtensions";

export const Editor = ({ onDataChange, content, editable }) => {
  const editor = useEditor({
    editable,
    extensions: extensions,
    editorProps: {
      attributes: {
        class:
          "prose dark:prose-invert prose-sm sm:prose-base lg:prose-lg max-w-none focus:outline-none prose-pre:bg-[#282c34] prose-pre:text-[#abb2bf] bg-white rounded-lg p-5",
      },
    },
    onUpdate: ({ editor }) => {
      const json = editor.getJSON();
      onDataChange(json);
    },
    content: content,
  });

  return (
    <div className="relative w-full ">
      {editable && <MenuBar editor={editor} />}
      {editable && (
        <div className="py-4 text-sm text-gray-600 ">
          Right now you can only use an image with a live URL within the text,
          I'm working on it...
        </div>
        // follow this for how to upload an image:
        // https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521
      )}
      <EditorContent editor={editor} />
    </div>
  );
};
