import React from "react";
import { Link } from "react-router-dom";
export default function Breadcrumbs({ data }) {
  return (
    <div className="flex items-center py-4 overflow-x-auto whitespace-nowrap">
      {data.map((item, index) => {
        return (
          <div
            key={`breadcrumbs-${index}`}
            className="text-black opacity-50 text-xs md:text-sm "
          >
            <Link to={item.link}>
              {item.name}
              {index !== data.length - 1 && <span className="px-3">/</span>}
            </Link>
          </div>
        );
      })}
    </div>
  );
}
